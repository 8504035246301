let inputs;
let passwords;
let flash;

const focusActive = () => {
    inputs.forEach(input => {
        let label = input.parentNode.querySelectorAll('[data-form-label]')[0];

        if (label) {
            input.addEventListener('focus', (e) => {
                label.classList.remove('hidden');
            });
            input.addEventListener('blur', (e) => {
                let value = e.target.value;
                if (!value) {
                    label.classList.add('hidden');
                }
            });
        }
    });
}

const togglePassword = () => {
    passwords.forEach(password => {
        let toggle = password.parentNode.querySelectorAll('[data-toggle-password]')[0];

        if (toggle) {
            toggle.addEventListener('click', e => {
                e.preventDefault();

                if (password.type === 'password'){
                    password.type = 'text';
                    toggle.innerHTML = 'Hide';
                } else {
                    password.type = 'password';
                    toggle.innerHTML = 'Show';
                }
                return false;
            });
        }
    });
};

const toggleFlash = () => {
    flash.forEach(flash => {
        let dismiss = flash.querySelectorAll('[data-flash-dismiss]');
        dismiss.forEach(dismiss => {
            dismiss.addEventListener('click', e => {
                flash.classList.add('hidden');
            });
        });
    });
}

export default function() {
    inputs = document.querySelectorAll('[data-form-input]');
    passwords = document.querySelectorAll('[data-form-password]');
    flash = document.querySelectorAll('[data-flash]');

    if (inputs) {
        focusActive();
    }

    if (passwords) {
        togglePassword();
    }

    if (flash) {
        toggleFlash();
    }
}
