let mq = window.matchMedia('(min-width: 768px)');
let videos = document.querySelectorAll('.js-video-autoplay');
var isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
};
// Only run at 768 and above
if(mq.matches) {
    function checkScroll() {
        // Loop through each video on the page
        videos.forEach(video => {
            // If the top and the bottom of the video are visible and its paused, start playing it
            if (isInViewport(video)) {
                if (video.paused) {
                    video.play();
                }
            // If the top and the bottom of the video are not visible and its playing, pause it
            } else {
                if (!video.paused) {
                    video.pause();
                }
            }
        })
    }

    // Check to see if the videos are visible on page load, then do the appropriate action
    window.addEventListener('load', checkScroll, false);
    // Check to see if the videos are visible when scrolling, then do the appropriate action
    window.addEventListener('scroll', checkScroll, false);
}
